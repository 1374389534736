<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="8" xl="5">
                <v-card class="axessia-card fill-height" outlined>
                    <v-toolbar class="mb-0 ma-4" dense flat>
                        <v-toolbar-title class="primary--text title">
                            Mentions légales
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text v-html="getConfig('legal_notice')">
                        
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'LegalNotice'
};
</script>
